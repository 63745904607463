export default defineNuxtRouteMiddleware(async (to) => {
    const safeRedirect = useSafeRedirect()
    const { isLoggedIn } = await useAuth()

    if (import.meta.client) {
        const hash = to?.hash

        const includesTermsHash = hash?.includes('terms')

        if (includesTermsHash) {
            const bundleId = hash.split('bundleId=')?.[1]

            if (bundleId) {
                return navigateTo({
                    ...to,
                    query: {
                        ...to.query,
                        terms: 'true',
                        bundleId,
                    },
                    hash: undefined,
                })
            }

            const promotionId = hash.split('promotionId=')?.[1]

            if (promotionId) {
                return navigateTo({
                    ...to,
                    query: {
                        ...to.query,
                        terms: 'true',
                        promotionId
                    },
                    hash: undefined,
                })
            }

            return navigateTo({
                ...to,
                query: {
                    ...to.query,
                    terms: 'true',
                },
                hash: undefined,
            })
        }
    }

    if (!isLoggedIn.value) return

    const returnUrl = to.query.returnUrl?.toString()

    if (returnUrl) {
        if (safeRedirect.isSafe(returnUrl)) {
            // Go to returnUrl
            return navigateTo({
                ...to,
                path: returnUrl,
                query: {
                    ...to.query,
                    returnUrl: undefined,
                },
            }, {
                external: true,
                redirectCode: 302,
            })
        }

        // Continue routing but remove returnUrl param
        return navigateTo({
            ...to,
            query: {
                ...to.query,
                returnUrl: undefined,
            },
        }, {
            external: true,
        })
    }

    if (to.query.auth) {
        if (to.query.auth === 'signup' || (to.query.auth === 'login' && to.query.mitid !== 'true')) {
            return navigateTo({ ...to, query: {
                ...to.query,
                auth: undefined,
            } })
        }
    }
})


// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/da'
import 'dayjs/locale/da'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isBetween from 'dayjs/plugin/isBetween'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import calendar from 'dayjs/plugin/calendar'


dayjs.extend(updateLocale)

dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)
dayjs.extend(isBetween)
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(calendar)

dayjs.tz.setDefault('Europe/Copenhagen')

// defaultLocale: ["da",{"relativeTime":{"future":"om %s","past":"%s siden","s":"få sek.","m":"1 min.","mm":"%d min.","h":"1 time","hh":"%d timer","d":"1 dag","dd":"%d dage","M":"1 måned","MM":"%d måneder","y":"1 år","yy":"%d år"},"calendar":{"lastDay":"[i går kl.] LT","lastWeek":"[sidste] dddd [kl.] LT","nextDay":"[i morgen kl.] LT","nextWeek":"dddd [kl.] LT","sameDay":"[i dag kl.] LT","sameElse":"L"}},"da",{"relativeTime":{"future":"om %s","past":"%s siden","s":"få sek.","m":"1 min.","mm":"%d min.","h":"1 time","hh":"%d timer","d":"1 dag","dd":"%d dage","M":"1 måned","MM":"%d måneder","y":"1 år","yy":"%d år"},"calendar":{"lastDay":"[i går kl.] LT","lastWeek":"[sidste] dddd [kl.] LT","nextDay":"[i morgen kl.] LT","nextWeek":"dddd [kl.] LT","sameDay":"[i dag kl.] LT","sameElse":"L"}}]


dayjs.updateLocale("da",{"relativeTime":{"future":"om %s","past":"%s siden","s":"få sek.","m":"1 min.","mm":"%d min.","h":"1 time","hh":"%d timer","d":"1 dag","dd":"%d dage","M":"1 måned","MM":"%d måneder","y":"1 år","yy":"%d år"},"calendar":{"lastDay":"[i går kl.] LT","lastWeek":"[sidste] dddd [kl.] LT","nextDay":"[i morgen kl.] LT","nextWeek":"dddd [kl.] LT","sameDay":"[i dag kl.] LT","sameElse":"L"}},"da",{"relativeTime":{"future":"om %s","past":"%s siden","s":"få sek.","m":"1 min.","mm":"%d min.","h":"1 time","hh":"%d timer","d":"1 dag","dd":"%d dage","M":"1 måned","MM":"%d måneder","y":"1 år","yy":"%d år"},"calendar":{"lastDay":"[i går kl.] LT","lastWeek":"[sidste] dddd [kl.] LT","nextDay":"[i morgen kl.] LT","nextWeek":"dddd [kl.] LT","sameDay":"[i dag kl.] LT","sameElse":"L"}})
dayjs.locale('da')


export default dayjs

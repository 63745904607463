import { default as redirect_45assistantdR2OcTBYo1Meta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/redirect-assistant.vue?macro=true";
import { default as pendingCvgvipgQTUMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/pending.vue?macro=true";
import { default as affiliateEoFnPtI33vMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/affiliate.vue?macro=true";
import { default as ansvarligt_45spilNH8U2mdOKTMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/ansvarligt-spil.vue?macro=true";
import { default as redirect_45assistantSxObPlTqEuMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/app/redirect-assistant.vue?macro=true";
import { default as bankoJbFNC2XKMUMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/banko.vue?macro=true";
import { default as betingelservPBmr2QNtzMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/betingelser.vue?macro=true";
import { default as bingox7tjqk4Iu8Meta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/bingo.vue?macro=true";
import { default as BlogcYSR0QicEGMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/Blog.vue?macro=true";
import { default as bonuskodegRDducjBhrMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/bonuskode.vue?macro=true";
import { default as casinorEq5SeiDbJMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/casino.vue?macro=true";
import { default as cookiepolitiknAvr134Uv1Meta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/cookiepolitik.vue?macro=true";
import { default as FAQ9tkqWVZsoIMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/FAQ.vue?macro=true";
import { default as _91slug_93oCNWbMhYkrMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/games/entrance/[slug].vue?macro=true";
import { default as indexyrzNm2BqhcMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/index.vue?macro=true";
import { default as indexkjJP6ZQfhVMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/konkurrence/index.vue?macro=true";
import { default as beskederkZof5yvWNzMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/konto/beskeder.vue?macro=true";
import { default as eid7gNzKRSvcsMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/konto/eid.vue?macro=true";
import { default as kundeservicet0P2ltCFQwMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/kundeservice.vue?macro=true";
import { default as om_45osvbK4Wgek0PMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/om-os.vue?macro=true";
import { default as logintd5UBFRN0uMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/playgame/login.vue?macro=true";
import { default as presseJgCa5H8uiqMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/presse.vue?macro=true";
import { default as privatlivspolitikV8fNVgGo6qMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/privatlivspolitik.vue?macro=true";
import { default as signupNbEG9dQ5pQMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/signup.vue?macro=true";
import { default as gemte_45spilKwePAoysRGMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/spilleautomater/gemte-spil.vue?macro=true";
import { default as indexREgxF4cD5BMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/spilleautomater/index.vue?macro=true";
import { default as spiloversigtiCITjgUrIxMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/spiloversigt.vue?macro=true";
import { default as _91seoName_93t3gQfcLWu9Meta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/spilside/[seoName].vue?macro=true";
import { default as tvNxR4IGjAvMMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/tv.vue?macro=true";
import { default as vinderehOZ7CZ3bhKMeta } from "/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/vindere.vue?macro=true";
export default [
  {
    name: "redirect-assistant",
    path: "/payments/redirect-assistant",
    meta: redirect_45assistantdR2OcTBYo1Meta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/redirect-assistant.vue")
  },
  {
    name: "pending",
    path: "/payments/pending",
    meta: pendingCvgvipgQTUMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/pending.vue")
  },
  {
    name: "affiliate",
    path: "/affiliate",
    meta: affiliateEoFnPtI33vMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/affiliate.vue")
  },
  {
    name: "ansvarligt-spil",
    path: "/ansvarligt-spil",
    meta: ansvarligt_45spilNH8U2mdOKTMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/ansvarligt-spil.vue")
  },
  {
    name: "app-redirect-assistant",
    path: "/app/redirect-assistant",
    meta: redirect_45assistantSxObPlTqEuMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/app/redirect-assistant.vue")
  },
  {
    name: "banko",
    path: "/banko",
    meta: bankoJbFNC2XKMUMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/banko.vue")
  },
  {
    name: "betingelser",
    path: "/betingelser",
    meta: betingelservPBmr2QNtzMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/betingelser.vue")
  },
  {
    name: "bingo",
    path: "/bingo",
    meta: bingox7tjqk4Iu8Meta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/bingo.vue")
  },
  {
    name: "Blog",
    path: "/Blog",
    meta: BlogcYSR0QicEGMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/Blog.vue")
  },
  {
    name: "bonuskode",
    path: "/bonuskode",
    meta: bonuskodegRDducjBhrMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/bonuskode.vue")
  },
  {
    name: "casino",
    path: "/casino",
    meta: casinorEq5SeiDbJMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/casino.vue")
  },
  {
    name: "cookiepolitik",
    path: "/cookiepolitik",
    meta: cookiepolitiknAvr134Uv1Meta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/cookiepolitik.vue")
  },
  {
    name: "FAQ",
    path: "/FAQ",
    meta: FAQ9tkqWVZsoIMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/FAQ.vue")
  },
  {
    name: "games-entrance-slug",
    path: "/games/entrance/:slug()",
    meta: _91slug_93oCNWbMhYkrMeta || {},
    alias: ["/spil/:slug","/spilleautomater/entrance/:slug"],
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/games/entrance/[slug].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexyrzNm2BqhcMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/index.vue")
  },
  {
    name: "konkurrence",
    path: "/konkurrence",
    meta: indexkjJP6ZQfhVMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/konkurrence/index.vue")
  },
  {
    name: "konto-beskeder",
    path: "/konto/beskeder",
    meta: beskederkZof5yvWNzMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/konto/beskeder.vue")
  },
  {
    name: "konto-eid",
    path: "/konto/eid",
    meta: eid7gNzKRSvcsMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/konto/eid.vue")
  },
  {
    name: "kundeservice",
    path: "/kundeservice",
    meta: kundeservicet0P2ltCFQwMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/kundeservice.vue")
  },
  {
    name: "om-os",
    path: "/om-os",
    meta: om_45osvbK4Wgek0PMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/om-os.vue")
  },
  {
    name: "playgame-login",
    path: "/playgame/login",
    meta: logintd5UBFRN0uMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/playgame/login.vue")
  },
  {
    name: "presse",
    path: "/presse",
    meta: presseJgCa5H8uiqMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/presse.vue")
  },
  {
    name: "privatlivspolitik",
    path: "/privatlivspolitik",
    meta: privatlivspolitikV8fNVgGo6qMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/privatlivspolitik.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupNbEG9dQ5pQMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/signup.vue")
  },
  {
    name: "spilleautomater-gemte-spil",
    path: "/spilleautomater/gemte-spil",
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/spilleautomater/gemte-spil.vue")
  },
  {
    name: "spilleautomater",
    path: "/spilleautomater",
    meta: indexREgxF4cD5BMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/spilleautomater/index.vue")
  },
  {
    name: "spiloversigt",
    path: "/spiloversigt",
    meta: spiloversigtiCITjgUrIxMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/spiloversigt.vue")
  },
  {
    name: "spilside-seoName",
    path: "/spilside/:seoName()",
    meta: _91seoName_93t3gQfcLWu9Meta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/spilside/[seoName].vue")
  },
  {
    name: "tv",
    path: "/tv",
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/tv.vue")
  },
  {
    name: "vindere",
    path: "/vindere",
    meta: vinderehOZ7CZ3bhKMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/1/spilnu/frontend/spilnu/pages/vindere.vue")
  }
]